import * as React from 'react';
import * as Rebass from 'rebass';
import styled from 'styled-components';

import { Layout } from '../../../components/layout';

export default () => (
  <Layout>
    <Rebass.Flex>
      <Rebass.Text>Success</Rebass.Text>
    </Rebass.Flex>
  </Layout>
)